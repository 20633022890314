@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.App {
    font-family: 'Montserrat' !important;
    font-weight: 500;
}

div {
    padding-bottom: 3px;
}

.calendar-side {}

.calendar {
    width: 100%;
    float: left;
    margin: 0 auto;
    border: 0px solid #CCC !important;
    color: rgb(22, 22, 22) !important;
}

.fc-toolbar-chunk {
    font-size: 30px !important;

}

.fc-title {
    font-size: 80%;
    color: rgb(22, 22, 22) !important;
}

.fc-event-title,
fc-timegrid-slot-label-cushion,
.fc-scrollgrid-shrink-cushion {
    color: rgb(22, 22, 22) !important;
}

.fc-day-header.fc-widget-header {
    padding: 5px;
    text-align: left !important;
    border: 0px solid #CCC !important;
}

.fc-event-main,
.fc-event-time,
.fc-v-event {
    border: 0px solid transparent !important;
}

.fc-event-main-frame {
    border-radius: 5px;
    position: relative;
    top: -10px;
    border: 1.5px solid rgb(182, 182, 182);
    background-color: lavender;
    padding-bottom: 10px !important;
}



td.fc-day.fc-widget-content {
    background: #302F2B !important;
}

td.fc-day-top {
    background: #302F2B !important;
}

td.fc-day-top.fc-other-month {
    opacity: 1 !important;
}

.fc-toolbar.fc-header-toolbar {
    border: 0px solid #273e47 !important;
    background: #273e47 !important;
    padding: 10px !important;
    margin-bottom: 0px !important;
    border-radius: 5px !important;
}

th,
td,
.fc-left {
    color: white;
}


.fc td {
    border: 0.5px medium rgb(160, 160, 160) !important;
}

.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
    padding-left: 5px;
    float: left !important;
}

.fc-list-day-cushion,
.fc-cell-shaded {
    font-size: 20px !important;
}

.fc-list-event {
    font-size: 15px !important;
    font-weight: 700 !important;
    color: rgb(22, 22, 22) !important;
}

.fc-list-event-time,
.fc-list-event-time:hover {
    color: #EAF4F4 !important;
    background-color: #273e47e5 !important;
    font-weight: 500 !important;
}

.fc-list-event:hover {
    background-color: #2121217e !important;
}

.fc-event,
.fc-event-dot {
    margin-top: 10px;
    padding: 2px;
}

.fc-day-grid-event .fc-content {
    white-space: normal;
}

.fc-col-header-cell {
    font-size: 14px !important;
    font-weight: 600 !important;
}

.fc-col-header {
    background: #FFF !important;
}

.fc-col-header-cell-cushion {
    color: #1e1e1e !important;
    font-weight: 700 !important;
}

.fc-daygrid-day-number {
    width: 100%;
    height: 1.875rem;
    background-color: transparent;
    color: rgb(22, 22, 22);
    text-align: center;
    text-decoration: none !important;
    border-radius: 8px;
    line-height: 1.875rem;
    font-weight: 700 !important;
    padding: 0 !important;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    cursor: pointer !important;
}

.fc-daygrid-event-dot {
    border: 0px solid #273e47 !important;
}


table {
    margin-bottom: 0px !important;
    border: 0px solid white !important;
}

table th {
    border: 0px !important;
}

table thead {
    border: 0px solid white !important;
}

.fc-daygrid-event-harness {
    background: #FFF;
    border-bottom: 1px solid #CCC !important;
    cursor: pointer;
}

.fc-toolbar-title {
    color: white;
    font-size: 16px !important;
}

.fc-button-group .fc-button {
    margin-left: 5px !important;

}

.fc-button {
    background: #f1f1f1 !important;
    color: #1e1e1e !important;
    border: 0px solid rgb(22, 22, 22) !important;
    font-weight: 600 !important;
    box-shadow: 0 2px 10px #1e1e1e !important;
    text-shadow: none !important;
    height: auto !important;
    font-size: 13px !important;
    width: 60px;

}

.fc-button:active,
.fc-button:focus {
    box-shadow: none !important;
    text-shadow: none !important;
    outline: none !important;

}

.fc-button {
    padding-left: 5px !important;
    padding-right: 5px !important;
    background-color: #ffffff !important;
    font-weight: 700 !important;
    border-radius: 0px !important;
}


.fc-next-button,
.fc-prev-button {
    padding-left: 5px !important;
    padding-right: 5px !important;
    background-color: transparent !important;
    color: white !important;
}

.fc-daygrid-day-top {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0.25rem;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
    font-size: 13px;
}



.fc-event-time {
    display: none;
}

.fc-list-event-dot {
    display: none !important;
    margin-right: 13px !important;
    border-color: #273e47 !important;
}

.fc-more-popover {
    background: #273e47 !important;
    border: 1px solid #273e47 !important;
}

.fc .fc-popover-header * {
    font-size: 15px !important;
}

.fc-timegrid-axis-cushion {
    font-size: 15px !important;
}

td .fc-scrollgrid-shrink-cushion {
    font-size: 15px !important;
}

.fc-timegrid-axis-frame {
    justify-content: center !important;
}


.fc-event-title-container {
    background-color: transparent !important;
    border: 0px solid grey !important;
    font-size: 15px !important;
    padding-top: 30px;
    

}

.fc-timegrid-event {
    background-color: transparent !important;
}

.fc-list-day-text,
.fc-list-day-time,
.fc-list-day-side-text {

    color: rgb(22, 22, 22) !important;
}

.fc-event-time,
.fc-event-title {
    padding: 0 1px;
    white-space: normal;
    font-weight: 600 !important;
    font-size: 0.85rem !important;
}

.fc-day-today {

    font-size: 1.5rem !important;
    background-color: rgba(245, 245, 245, 0.466) !important;
}



.fc-day-today>fc-daygrid-day-number {
    background-color: #302F2B !important;
}

.fc-title {
    white-space: normal;
}

@media (min-width: 768px) {
    .fc-daygrid-day-frame {
        padding: 0rem !important;
    }
}


@media(max-width: 767px) {
    .fc-toolbar.fc-header-toolbar {
        display: flex;
        flex-direction: column;
    }

    .fc-toolbar.fc-header-toolbar .fc-left {
        order: 3;
    }

    .fc-toolbar.fc-header-toolbar .fc-center {
        order: 1;
    }

    .fc-toolbar.fc-header-toolbar .fc-right {
        order: 2;
    }
}

@media screen and (max-width:767px) {
    .fc-toolbar.fc-header-toolbar {
        font-size: 60%
    }
}

.fc-list-day-text,
.fc-list-day-side-text {
    color: #131313 !important;
    font-size: medium !important;
}


.fc-list-event-title {
    color: #141414 !important;
    font-weight: 600 !important;
}


/* REACT CALENDAR STYLING - START */
.react-calendar__month-view__days>.react-calendar__tile,
.react-calendar__month-view__weekdays__weekday {
    flex-basis: 15% !important;
    max-width: 20% !important;
    min-width: 20% !important;
    padding-bottom: 5px !important;
    padding-top: 5px !important;
    padding-left: 1px !important;
    padding-right: 1px !important;
    border: none !important;
    width: 0px !important;
    border-radius: 4px !important;
}


.react-calendar__month-view__weekdays__weekday:nth-child(1),
.react-calendar__month-view__weekdays__weekday:nth-child(7) {
    display: none;
}

.react-calendar__month-view__weekdays__weekday {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    background-color: #2e3747f3 !important;
    color: white !important;
    border-radius: 0px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.react-calendar {
    display: flexbox;
    padding: 0px;
    width: 100% !important;
    background-color: #fff;
    color: #222;
    border-radius: 8px;
    border: none !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-family: 'Montserrat' !important;
    line-height: 0em !important;
}

.react-calendar__navigation button {
    color: #273e47f3;
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 4px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #f8f8fa;
}

.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}


.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
    display: none !important;
}

abbr {
    text-decoration: none;
    font-weight: 700 !important;
    font-family: 'Montserrat';
}

fc-col-header-cell-cushion .react-calendar__tile {
    padding: 3px 0px 3px 0px !important;
    font-family: 'Montserrat' !important;
    font-weight: 500;

}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background: #f8f8fa;
    color: #273e47f3;
    border-radius: 6px;
}

.react-calendar__tile--now {
    background: #EAF4F4 !important;
    border-radius: 6px;
    font-weight: bold;
    color: #273e47f3;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #273e47f3;
    border-radius: 6px;
    font-weight: bold;
    color: #273e47f3;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #f8f8fa;
}

.react-calendar__tile--active {
    background: #273e47f3 !important;
    border-radius: 6px;
    font-weight: bold;
    color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #273e47f3;
    color: white;
}


.react-calendar__month-view__days__day--weekend {
    display: none;
}

/* REACT CALENDAR STYLING - END */


.header {
    background-color: #273e47;
    margin-bottom: 10px;
    width: 100%;
    min-height: 40px;
    max-height: 40px;
    text-align: justify;
    box-shadow: 0 1px 5px #5e5e5e;
}

.nav-button {
    font-family: 'Montserrat' !important;
    text-decoration: none !important;
    color: #EAF4F4 !important;
    font-size: 15px !important;
    padding-bottom: 10px !important;
    padding-top: 7px !important;
    margin-top: 0px !important;
    border-radius: 0px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    text-transform: none !important;

}

Link :hover {
    background-color: whitesmoke !important;

}

.nav-link {
    text-decoration: none !important;
    font-weight: bold !important;
}

.nav-title {
    text-align: left;
}

.sideBar {
    height: 100%;
    width: 20%;
    align-items: flex-start;

}




.create-button {
    text-align: center;
    background-color: white;
    box-shadow: 0 1px 5px #535353;
    padding: 5px;
    margin: 20px 10px 0px 10px;
    width: max-content;
    border-radius: 1.5rem;
    font-weight: bolder;
}



.create-button>button {
    color: #273e47;
    font-weight: 700;
    text-decoration: none;
}

Card {
    font-family: 'Montserrat' !important;
}

.card-close-button {
    justify-content: end;
    text-align: end;

}



Grid {
    font-family: 'Montserrat' !important;
}

Link {
    text-decoration: none !important;
    color: #273e47 !important;

}

.create-card {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 0px solid transparent;
    transform: translate(-50%, -50%);
    background-color: '#fff';
    font-family: 'Montserrat';
    font-weight: 600;
    text-align: left;
    color: #1f1f1f;
    box-shadow: 1px 5px 1px #000000;
    padding-left: 10;
    padding-right: 10;
    
    min-width: 25%;
    max-width: fit-content;
    scroll-behavior: smooth;
    overflow: scroll;
}

Typography {
    font-family: "Montserrat" !important;
    text-transform: none !important;
}


.calendar-box,
.mybookings-div {
    position: relative;
    top: 50px;
}

.react-calendar__navigation__label {

    font-weight: bolder !important;
    font-family: 'Montserrat' !important;
    font-size: medium !important;
    text-transform: uppercase !important;
    
}

.login-body {
    height: 100%;
}

.login-page-box {
    padding-top: 100px;
    padding-bottom: 70px;
    background-image: url(assets/background.png);
    background-size: cover;
    min-height: 100%;
    background-repeat: repeat;


}


.login-div {
    /* background-image: url(assets/background.png); */
    background-size: contain;
    background-repeat: no-repeat;

    display: flex;
    text-align: center;
    justify-content: center;
    background-color: #ffffff;
    width: 330px;
    height: 500px;
    font-family: 'Montserrat';
    font-weight: 600;
    text-align: left;
    color: white !important;
    box-shadow: 0px 1px 10px #333333;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.login-textfield {
    color: white;
    margin-top: 15px !important;

}

.login-button {
    margin-top: 30px !important;
    text-align: center !important;
    justify-content: center !important;
    background-color: #273e47 !important;

}

label {
    color: rgb(85, 85, 85);
    font-weight: 500;
}


.container {
    position: absolute;
    top: 60px;
    justify-content: center;
    text-align: center;
}

.suggestion-box {
    border-radius: 5px;
    cursor: pointer;
    text-align: left;
    border: 0px dotted rgb(158, 158, 158);
    padding: 5px;
    font-weight: 500;
}

.suggestion-box:hover {
    background-color: rgb(211, 211, 211);
}